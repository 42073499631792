import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, message as messageAntd } from 'antd';
import './ChatBubble.css';
import TextArea from 'antd/es/input/TextArea';
import { AppDispatch } from '../../redux/store/store';
import { useDispatch } from 'react-redux';
import { UploadMessagesTrainReducer } from '../../redux/actions/chatBots/Chat/Chat';

interface ChatBubbleProps {
  idConversation: number;
  idMessage: number;
  message: string;
  sender: 'emisor' | 'receptor';
  editBubble?: boolean;
  fontSize: string;
  fontFamily: string;
  colorTextoEmisor?: string;
  colorTextoReceptor?: string;

}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  idConversation,
  idMessage,
  message,
  sender,
  editBubble = false,
  fontSize,
  fontFamily,
  colorTextoEmisor,
  colorTextoReceptor
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [open, setOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState(message);
  const [newMessage, setNewMessage] = useState(message);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <div className={`chat-bubble ${sender} `} style={{ position: 'relative', color: sender === "emisor" ? colorTextoEmisor : colorTextoReceptor }}>
      <p style={{ fontSize: `${fontSize}px`, fontFamily: fontFamily }}>
        {editMessage ? defaultMessage : message}
      </p>

      {editBubble ? (
        <div
          style={
            sender == 'receptor'
              ? {
                position: 'absolute',
                top: '9px',
                right: '-20px',
                cursor: 'pointer',

              }
              : {
                position: 'absolute',
                top: '9px',
                left: '-22px',
                color: 'black',
                cursor: 'pointer',
              }
          }
          onClick={showModal}
        >
          <EditOutlined />
        </div>
      ) : null}

      <Modal
        title="Editar mensaje"
        open={open}
        onOk={async () => {
          await dispatch(UploadMessagesTrainReducer(sender, idConversation, newMessage, idMessage))
          messageAntd.success('Usuario desactivado correctamente');
          hideModal()
          setDefaultMessage(newMessage)
          setEditMessage(true)
        }}
        onCancel={hideModal}
        okText="Aceptar"
        cancelText="Cancelar"
      >
        <TextArea
          defaultValue={defaultMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default ChatBubble;
