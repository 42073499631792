import React, { useEffect, useRef, useState } from 'react';
import { Input, Avatar, Tooltip, Button } from 'antd';
import {
  CheckCircleTwoTone,
  SendOutlined,
  UserOutlined,
  RobotOutlined, CloseCircleOutlined
} from '@ant-design/icons';
import ChatBubble from './ChatBubble';
import { AppDispatch, RootState } from '../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CreateConversationReducer, CreateMessageTrainReducer, ResetConversationReducer } from '../../redux/actions/chatBots/Chat/Chat';
import {
  ReloadOutlined
} from '@ant-design/icons';
import { CreateConversationSupportReducer } from '../../redux/actions/chatBots/Chat/ChatSupport';
import { GetOneDesingChatReducer, GetOneDesingChatSupportReducer } from '../../redux/actions/chatBots/Chat/ChatDesing';
import { fetchChatbotHorarios } from '../../redux/actions/Horario/ChatBotHorario';
import { UpdateChatSeccionadoHome, UpdateSupportSeleccionadoHome } from '../../redux/actions/home/Home';
import { useParams } from 'react-router-dom';
import { CreateConversationEmbeddedReducer } from '../../redux/actions/embedded/Embedded';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('es');

interface Message {
  id: number;
  text: string;
  sender: 'emisor' | 'receptor';
}
interface ChatProps {
  idConversation?: number;
  editBubble?: boolean;
  modeBot?: boolean;
  iaActivate?: boolean;
  data?: Array<any>;
  fontSize?: string;
  fontFamily?: string;
  nombreChat?: string;
  inputPlaceholder?: string;
  logoChat?: string;
  iconoEnviarChat?: string;
  logoPreview?: string | null;
  iconoPreview?: string | null;
  resetChat?: boolean;
  supportChat?: boolean;
  embedded?: boolean;
  estadoChat?: boolean;
  coloresStyle?: {
    colorCabecera?: string;
    colorTextoEmisor?: string;
    colorTextoReceptor?: string;
    colorTitulo?: string;
    colorEstado?: string;
  }

  typingStatus?: boolean;
  idChatBot?: number;
  tokenChatbot?: string;
  disabledInput?: boolean;
  estadoHorario?: boolean;
}

const ChatComponent: React.FC<ChatProps> = ({

  idConversation = 0,
  editBubble = true,
  modeBot = false,
  iaActivate = true,
  data,
  fontSize = '16px',
  fontFamily = 'Arial',
  nombreChat = 'Nombre del chat',
  inputPlaceholder = 'Tipear un mensaje',
  logoChat = null,
  iconoEnviarChat = null,
  resetChat = true,
  supportChat = false,
  embedded = false,
  estadoChat,
  logoPreview = "",
  iconoPreview = "",
  coloresStyle = {
    colorCabecera: '#1677ff',
    colorTextoEmisor: '#FFFFFF',
    colorTextoReceptor: '#000000',
    colorTitulo: '#FFFFFF',
    colorEstado: '#0BF732'
  },
  typingStatus = false,
  idChatBot,
  tokenChatbot = "",
  disabledInput = false,
  estadoHorario
}) => {
  const dispatch: AppDispatch = useDispatch();

  const { token } = useParams();
  const [messages, setMessages] = useState<Message[]>([]);
  const [displayedText, setDisplayedText] = useState<string>(''); // Estado para el texto que se va mostrando
  const [isTyping, setIsTyping] = useState<boolean>(false); // Estado para el "escribiendo..."
  const [isChatbotActive, setIsChatbotActive] = useState<boolean>(false);
  const {
    rex_data
  } = useSelector(({ chatsbotsHorario }: any) => chatsbotsHorario);

  const {
    rex_user_auth
  } = useSelector(({ auth }: any) => auth);

  const { estado_user } = rex_user_auth?.estado_user || {};

  const {
    rex_chatsbots
  } = useSelector(({ home }: any) => home);

  const { rex_state_update_desing, rex_design_chat, rex_design_status, rex_styles } = useSelector((state: RootState) => state.design);
  const { rex_chat_selecccionado } = useSelector(({ home }: RootState) => home);
  const selectedChatId = rex_chat_selecccionado

  const selectedChatbot = rex_chatsbots.find((bot: any) => bot.id === selectedChatId);

  useEffect(() => {
    if (selectedChatbot && rex_user_auth?.timezone) {
      updateChatbotStatus();
    }
  }, [selectedChatbot, rex_user_auth]);

  const [newMessage, setNewMessage] = useState<string>('');
  const [sender, setSender] = useState<'emisor' | 'receptor'>('emisor');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();

    if (supportChat) {
      dispatch(GetOneDesingChatSupportReducer());

    } else {

      dispatch(GetOneDesingChatReducer());
    }

  }, []);

  useEffect(() => {
    if (data?.length) {
      setMessages(data);
    } else {
      setMessages([]);
    }
  }, [data]);

  useEffect(() => {
    dispatch(fetchChatbotHorarios());
  }, []);

  const handleSendMessage = async (messageSend: string) => {
    if (newMessage.trim()) {
      setNewMessage('');

      const messageEmit: Message = {
        id: messages.length + 1,
        text: messageSend,
        // sender: 'emisor',
        sender: sender,
      };

      setMessages((prevMessages) => [...prevMessages, messageEmit]);
      scrollToBottom();

      if (!isChatbotActive) {
        const unavailableMessage: Message = {
          id: messages.length + 2,
          text: "El chatbot no está disponible en este horario.",
          sender: 'receptor',
        };
        setMessages((prevMessages) => [...prevMessages, unavailableMessage]);
        await dispatch(CreateConversationReducer(messageSend));
        return;
      }

      // Guardar mensaje emisor
      if (modeBot) {
        dispatch(CreateMessageTrainReducer('emisor', idConversation, messageSend));
      }

      const rpta_bot: any = embedded ? token
        ? await dispatch(CreateConversationEmbeddedReducer(messageSend, token))
        : 0
        : supportChat
          ? await dispatch(CreateConversationSupportReducer(messageSend))
          : await dispatch(CreateConversationReducer(messageSend));
      setIsTyping(false);

      // Lógica para mostrar la respuesta del bot letra por letra
      const botMessage = rpta_bot.contenido;
      let currentText = ''; // Texto que se va mostrando
      let index = 0;

      const typingEffect = () => {
        if (index < botMessage.length) {
          currentText += botMessage.charAt(index);
          setDisplayedText(currentText); // Actualiza el texto mostrado
          index++;
          setTimeout(typingEffect, 25); // Intervalo para mostrar cada letra (50ms)
        } else {
          const messageRecived: Message = {
            id: messages.length + 2,
            text: botMessage,
            sender: 'receptor',
          };
          setMessages((prevMessages) => [...prevMessages, messageRecived]);
          setDisplayedText(''); // Limpiamos el texto mostrado cuando se termina de escribir
        }
      };

      typingEffect();
      scrollToBottom();

      if (modeBot) {
        dispatch(CreateMessageTrainReducer('receptor', idConversation, botMessage));
      }

      // CODIGO ANTERIOR PARA IDENTIFICAR CUANDO ES EMISOR Y RECEPTOR -> PARA ENTRENAR

      // if (modeBot) {
      //   dispatch(CreateMessageTrainReducer(sender, idConversation, messageSend));
      //   setSender(sender === 'emisor' ? 'receptor' : 'emisor');        
      // } 

    }
  };

  const updateChatbotStatus = () => {
    const timezone = rex_user_auth.timezone;
    const currentDay = dayjs().tz(timezone).format('dddd').toLowerCase();
    const currentTime = dayjs().tz(timezone).format('HH:mm');
    const daySchedule = selectedChatbot?.horariosActividad?.find((schedule: any) => schedule.dia === currentDay);
    if (daySchedule) {
      const [startTime, endTime] = daySchedule.horario.split(' - ');
      if (currentTime >= startTime && currentTime <= endTime) {
        setIsChatbotActive(true);
      } else {
        setIsChatbotActive(false);
      }
    } else {
      setIsChatbotActive(false);
    }
  };


  const hasImage = logoPreview || logoChat;

  return (
    <>
      <div
        style={{
          // width: '95%',
          height: '500px',
          // background: 'red',
          borderRadius: '25px',
          position: 'relative',
          border: '1px solid #C4C4C4',
          boxShadow: ' 0 0px 8px rgba(12, 12, 12, 0.2)'
        }}
      >
        {/* HEAD */}
        <div
          style={{
            display: 'flex',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '10px',
            paddingBottom: '10px',
            background: coloresStyle.colorCabecera,
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px',
            marginBottom: '10px',
            color: 'white',
            alignItems: 'center',
          }}
        >
          {/* <Button>
          aqui
        </Button> */}
          <div
            style={{
              marginRight: '20px',
              background: 'transparent'
            }}
          >
            <Avatar
              size={40}
              src={logoPreview || (logoChat && logoChat !== "http://url" ? logoChat : undefined)}
              icon={logoChat === "http://url" ? <UserOutlined /> : undefined}
              style={{
                background: '#E6F4FF',
                color: "black",
                borderRadius: '100%',
                marginRight: '10px',
                width: '40px',
                height: '40px',
                alignContent: 'center',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            />


          </div>
          <div
            style={{
              background: 'transparent'
            }}
          >
            <div
              style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '1', background: 'transparent', color: coloresStyle.colorTitulo }}
            >
              {nombreChat}
            </div>
            <div
              style={{
                background: 'transparent',
                marginTop: '4px',
                color: ' #0C5257'
              }}
            >

              <CheckCircleTwoTone
                twoToneColor={coloresStyle.colorEstado}
              />
              {/* <span
                style={{
                  marginLeft: '5px',
                  fontSize: '14px',
                  // fontFamily: 'cursive',
                  color: `${coloresStyle.colorEstado}`,
                  fontWeight: 'bold'
                }}
              >
                 {selectedChatbot?.estado_chatbot ? 'Activo' : 'Inactivo'}         
              </span> */}
              <span></span>
              <span>
                <span
                  style={{
                    marginLeft: '5px',
                    fontWeight: 'bold',
                    color:
                      estadoHorario ? '#0BF732' : 'red'
                  }}
                >
                  {estadoHorario ? 'Activo' : 'Inactivo'}
                </span>
              </span>
              {/* {estadoChat ? (
                <>
                  <CheckCircleTwoTone twoToneColor="blue" />
                  <span style={{ marginLeft: '5px' , color:"blue" }}>Disponible</span></>
              ) : (
                <>
                  <CloseCircleOutlined style={{ fontSize: '18px', color: 'red' }} />

                  <span style={{ marginLeft: '5px' ,color : "" }}>Desabilitado</span>
                </>
              )} */}

            </div>
          </div>
          {
            resetChat ? (
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  background: 'transparent',
                  color: ' #0C5257'
                }}
              >
                <Tooltip
                  title="Nuevo Chat"
                >
                  <ReloadOutlined
                    style={{
                      fontSize: '20px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      dispatch(UpdateChatSeccionadoHome("0"))
                      //dispatch(UpdateSupportSeleccionadoHome("0"))
                      dispatch(ResetConversationReducer())
                    }}
                  />
                </Tooltip>
              </div>
            ) : null
          }

        </div>

        {/* BODY */}
        <div
          style={{
            width: '100%',
            margin: '0 auto',
            height: '400px',
            overflowY: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '60px'
            }}
          >
            {messages.map((message) => (
              <ChatBubble
                key={message.id}
                idConversation={idConversation}
                idMessage={message.id}
                message={message.text}
                sender={message.sender}
                fontSize={fontSize}
                fontFamily={fontFamily}
                editBubble={editBubble}
                colorTextoEmisor={coloresStyle.colorTextoEmisor}
                colorTextoReceptor={coloresStyle.colorTextoReceptor}
              />
            ))}

            {/* Mostrar "escribiendo..." si el bot está cargando */}
            {isTyping && <div style={{ fontStyle: 'italic', color: '#999' }}>Escribiendo...</div>}

            {/* Mostrar el texto letra por letra del bot */}
            {displayedText && (
              <ChatBubble
                idConversation={idConversation}
                idMessage={0}
                message={displayedText}
                sender="receptor"
                fontSize={fontSize}
                fontFamily={fontFamily}
                editBubble={editBubble}
                colorTextoEmisor={coloresStyle.colorTextoEmisor}
                colorTextoReceptor={coloresStyle.colorTextoReceptor}
              />
            )}

            <div ref={messagesEndRef} />
            {typingStatus && <div style={{ fontStyle: 'italic', color: '#999' }}>El usuario está escribiendo...</div>}
          </div>
        </div>


        {/* BOTTOM */}
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            background: 'white',
            width: '100%',
            height: '60px',
            // paddingLeft: '20px',
            // paddingRight: '20px',
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center',
            borderBottomLeftRadius: '25px',
            borderBottomRightRadius: '25px',
          }}
        >
          {modeBot ? (
            <div
              style={{
                background: '#E6F4FF',
                borderRadius: '100%',
                marginRight: '0px',
                marginLeft: '10px',
                width: '40px',
                height: '40px',
                alignContent: 'center',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSender(sender === 'emisor' ? 'receptor' : 'emisor');
              }}
            >
              {sender == 'emisor' ? <UserOutlined /> : <RobotOutlined />}
            </div>
          ) : null}

          {/* BOTON DE ENVIAR */}
          <Input
            // disabled={rex_user_auth?.estado_user === 'Inactivo'}
            style={{
              borderRadius: '20px',
              height: '40px',
              marginLeft: '10px',
              marginRight: '20px'
            }}
            type="text"
            value={newMessage}
            onChange={(e) => { setNewMessage(e.target.value); }}
            placeholder={inputPlaceholder}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSendMessage(newMessage)
              }
            }}
            suffix={
              <div
                style={{
                  borderRadius: '100%',
                  cursor: 'pointer',
                }}
                onClick={() => handleSendMessage(newMessage)}
              >
                {iconoPreview ? (

                  <img
                    src={iconoPreview}
                    alt={iconoPreview}
                    style={{
                      marginTop: "8px",
                      background: "green",
                      borderColor: "black",
                      borderWidth: "2px",
                      width: '30px',
                      height: '30px',
                      borderStyle: "solid",
                      borderRadius: "8px",
                      objectFit: 'cover',

                    }}
                  />
                ) : (iconoEnviarChat == "http://url" ? (<SendOutlined />) : (
                  <img
                    src={iconoEnviarChat || ""}
                    alt={iconoEnviarChat || ""}
                    style={{
                      marginTop: "8px",
                      background: "green",
                      borderColor: "black",
                      borderWidth: "2px",
                      width: '30px',
                      height: '30px',
                      borderStyle: "solid",
                      borderRadius: "8px",
                      objectFit: 'cover',

                    }}
                  />
                ))}
              </div>
            }
            disabled={disabledInput}
          />
        </div>
      </div >
    </>
  );
};

export default ChatComponent;